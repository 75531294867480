<template>
     <div id="gallery">
        <v-container fluid >
         <v-container class="mt-5 pt-5">
           <breadcrumbs></breadcrumbs>
         </v-container>
        </v-container>
        
        <v-container fluid class="dark my-5 py-5">
          <v-progress-linear
                  v-if="loading"
                  :color="(loading) ? 'primary' : 'grey'"
                  :indeterminate="loading"
                  ></v-progress-linear>
          <v-row>
            <v-col cols="12" md="4" v-for="(photo,n) in photos" :key="n">
                <v-img
                    class="mx-auto mb-2"
                    width="100%"
                    aspect-ratio="1"
                    :src="photo.url"
                    :alt="photo.alt"
                >
                </v-img>
            </v-col>
          </v-row>
        </v-container>

     </div>
   </template>
   
   <script>
     import {mapGetters} from "vuex";
    //  import GalleryPhotos from '@/components/Layout/Sections/GalleryPhotos.vue';

     export default {
       name: 'FotoGallery',
       components:{
          // GalleryPhotos
        },
       computed: {
        ...mapGetters({
              photos: 'photos/list',
              loading: 'loading/loading',
          }),
        },
       data: () => ({
         title: process.env.VUE_APP_BASE_NAME,
       }),
       created() {
        

        console.log('this.photos.length',this.photos.length)
        if(!this.photos.length){
          this.getData()
        }

     },
     methods:{
      async getData(){
          await this.$store.dispatch('photos/getData')
     },
     }
     }
   </script>
   <style scoped>
   #home{
     min-height: 100%;
   }
   </style>